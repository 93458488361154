import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

const I = styled.i`
  &:before {
    margin-left: 0;
    margin-right: 0;
  }
`

/**
 * A bottom navigation on page
 * has Back and Next buttons
 */
export default function Navigation({ location }) {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            slug
            headings(depth: h1) {
              value
            }
            frontmatter {
              title
              date
              draft
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const allUrls = data.allMdx.edges
    .filter(
      ({ node }) =>
        node.fields.slug.startsWith("/blog/") && !node.frontmatter.draft
    )
    .map(({ node }) => ({ ...node.frontmatter, url: node.slug }))
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map(n => n.url)

  const currentUrl = location.split("/").filter(a => !!a)[1]

  const currentIndex = allUrls.findIndex(url => url === currentUrl)

  return (
    <nav>
      {currentIndex > -1 && currentIndex < allUrls.length - 1 && (
        <Link to={`/blog/${allUrls[currentIndex + 1]}`}>
          <I className="icon-angle-left" />
          Previous
        </Link>
      )}{" "}
      {currentIndex > 0 && (
        <Link to={`/blog/${allUrls[currentIndex - 1]}`}>
          Next
          <I className="icon-angle-right" />
        </Link>
      )}{" "}
      <Link to="/blog/archive">List</Link>
    </nav>
  )
}
