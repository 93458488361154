import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import BlogNavigation from "./blog-navigation"
import Layout from "./page-layout"

const shortcodes = { Link }

export default function BlogLayout({ data: { mdx }, location }) {
  const { date } = mdx.frontmatter

  return (
    <Layout location={location.pathname}>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {date && <div>{date}</div>}
        <BlogNavigation location={location.pathname} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      slug
      frontmatter {
        title
        date
        draft
      }
    }
  }
`
